import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Typewriter } from 'react-simple-typewriter';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import 'animate.css/animate.min.css';
import './LandingPage.css';

const Particles = () => {
  const pointsRef = useRef();

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    pointsRef.current.rotation.y = elapsedTime * 0.1;
  });

  const count = 5000;
  const positions = new Float32Array(count * 3);
  for (let i = 0; i < count; i++) {
    const x = (Math.random() - 0.5) * 20;
    const y = (Math.random() - 0.5) * 20;
    const z = (Math.random() - 0.5) * 20;
    positions.set([x, y, z], i * 3);
  }

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.2} // Increased size for better visibility
        color="#ff7f50"
        sizeAttenuation
        transparent
        depthWrite={false}
      />
    </points>
  );
};

const LandingPage = () => {
  const { ref: videoRef, inView: videoInView } = useInView({ triggerOnce: true });
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });

  return (
    <div className="landing-page">
      <Canvas className="canvas">
        <OrbitControls enableZoom={false} />
        <ambientLight intensity={1} />
        <directionalLight position={[2, 5, 2]} intensity={2} />
        <Particles />
      </Canvas>
      <div className="hero-section">
        <h1>
          KeywordRanker for{' '}
          <span className="typewriter-text">
            <Typewriter
              words={['Thumbnails', 'Descriptions', 'Tags', 'Titles']}
              loop={true}
              cursor
              cursorStyle="|"
              typeSpeed={80}
              deleteSpeed={50}
              delaySpeed={1500}
            />
          </span>
        </h1>
        <p className="subheading">The Ultimate Tool for Discovering Ranking Keywords for Your Type Beats</p>
        <Link to="/keyword-finder" className="hero-button">
          Use KeywordRanker
        </Link>
      </div>
      <div className="video-section">
        <div className="video-container">
          <iframe
            ref={videoRef}
            className={`animate__animated ${videoInView ? 'animate__fadeInLeft' : ''}`}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vnu-RDcwSMg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div ref={textRef} className={`video-description animate__animated ${textInView ? 'animate__fadeInRight' : ''}`}>
            <h2>Maximize Your Reach with KeywordRanker</h2>
            <p>Discover how our tool can expand your typebeats reach to new heights, giving you only the best keywords with high volume and less competition.</p>
          </div>
        </div>
        <Link to="/keyword-finder" className="video-button">
          Use KeywordRanker
        </Link>
      </div>
      <footer>
        <p>© 2024 KeywordRanker. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
