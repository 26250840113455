import React, { useState, useEffect, useRef, useCallback } from 'react';
import './KeywordFinder.css';

const randomTitles = [
  "Visionary", "Blaze", "Phantom", "Vortex", "Surge", "Pulse", "Mirage", "Nova", "Echo", "Nebula", "Frost", "Zenith", "Rogue", "Quantum", "Mystic", "Volt", "Solace", "Specter", "Eclipse", "Cinder", "Karma", "Lucid", "Prism", "Cipher", "Apex", "Drift", "Halo", "Mirage", "Reverie", "Pioneer", "Zephyr", "Spectra", "Ether", "Tempest", "Cascade", "Rift", "Luminous", "Zen", "Abyss", "Fusion", "Bane", "Glint", "Bliss", "Fable", "Obsidian", "Euphoria", "Saga", "Rapture", "Flare", "Nexus", "Nimbus", "Seraph", "Eon", "Enigma", "Echo", "Blitz", "Celestial", "Radiant", "Tidal", "Vivid", "Luminary", "Ascend", "Vertex", "Sonic", "Wraith", "Nova", "Arcane", "Aurora", "Ember", "Sable", "Pulse", "Veil", "Shroud", "Zen", "Echo", "Aether", "Sonic", "Blitz", "Zephyr", "Arc", "Gleam", "Vortex", "Sonic", "Astral", "Crest", "Fury", "Pyro", "Rune", "Shard", "Torque", "Zion", "Aegis", "Rift", "Specter", "Lucent", "Stellar", "Inferno", "Aurora", "Aether", "Catalyst", "Quasar", "Radiant", "Nexus", "Stratos"
];

const KeywordFinder = () => {
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalChars, setTotalChars] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [titles, setTitles] = useState([]);
  const [description, setDescription] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [customName, setCustomName] = useState('');
  const [defaultTitles, setDefaultTitles] = useState([]);
  const [defaultDescription, setDefaultDescription] = useState('');
  const [randomTitle, setRandomTitle] = useState('');
  const [baseImage, setBaseImage] = useState('');
  const canvasRef = useRef(null);

  const generateTitles = useCallback(() => {
    const titles = keywords.slice(0, 4).map((keyword, index) => {
      const typeBeatKeyword = keyword.keyword.replace(/type\s?beat/i, '').trim();
      const selectedRandomTitle = randomTitles[Math.floor(Math.random() * randomTitles.length)];
      const currentYear = new Date().getFullYear();

      const templates = [
        `[FREE] ${typeBeatKeyword} x ${keywords[1]?.keyword.replace(/type\s?beat/i, '').trim()} Type Beat “${selectedRandomTitle}”`,
        `${keywords[1]?.keyword.replace(/type\s?beat/i, '').trim()} Type Beat ${currentYear} x ${keywords[2]?.keyword.replace(/type\s?beat/i, '').trim()} Type Beat - "${selectedRandomTitle}"`,
        `(FREE) ${typeBeatKeyword} Type Beat - "${selectedRandomTitle}" | Free Type Beat ${currentYear} | ${keywords[3]?.keyword.replace(/type\s?beat/i, '').trim()} Rap Instrumental`,
        `[FREE] ${keywords[2]?.keyword.replace(/type\s?beat/i, '').trim()} x ${keywords[3]?.keyword.replace(/type\s?beat/i, '').trim()} x ${typeBeatKeyword} Type Beat | "${selectedRandomTitle}"`,
        `${typeBeatKeyword} Type Beat - "${selectedRandomTitle}" | Free Type Beat | Rap/Trap Instrumental ${currentYear}`
      ];

      return templates[index % templates.length];
    });

    setTitles(titles);
    setDefaultTitles(titles);
    generateDescription(titles);
  }, [keywords]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const generateDescription = (titles) => {
    const firstTitle = capitalizeWords(titles[0]);
    const tags = keywords.slice(0, 6).map((keyword) => keyword.keyword.replace(/ /g, '')).join(' ');

    const description = `
${firstTitle}

This beat is FOR NON-PROFIT USE ONLY. NOT FREE EVERYWHERE.
ANYONE WHO RELEASES A SONG WITHOUT A LEASE WILL BE HIT COPYRIGHT.

© 2024 [Producer Name]. All Rights Reserved. Must Credit prod. [producer Name} in Title

[Contact with me]
📱 Instagram: [producer IG]
📩 For any questions: [producer@email.com]
💸Beat Store: [beatstore]

Thanks to everyone who likes, comments and subs to my channel!
You really motivate me to keep working 💕

${tags.split(' ').map(tag => `#${tag}`).join(', ')}
`;

    setDescription(description.trim());
    setDefaultDescription(description.trim());
  };

  const updateTitlesWithCustomName = useCallback(() => {
    const updatedTitles = defaultTitles.map(title => title.replace(/“[^”]*”/, `“${customName}”`));
    setTitles(updatedTitles);

    const updatedDescription = defaultDescription.replace(/“[^”]*”/, `“${customName}”`);
    setDescription(updatedDescription);
    if (baseImage) {
      generateThumbnail(baseImage, customName).then(setThumbnailUrl);
    }
  }, [customName, defaultTitles, defaultDescription, baseImage]);

  useEffect(() => {
    if (keywords.length > 0) {
      const selectedRandomTitle = randomTitles[Math.floor(Math.random() * randomTitles.length)];
      setRandomTitle(selectedRandomTitle);
      generateTitles();
      fetchAndGenerateThumbnail(selectedRandomTitle);
    }
  }, [keywords, generateTitles]);

  useEffect(() => {
    if (customName) {
      updateTitlesWithCustomName();
    } else {
      setTitles(defaultTitles);
      setDescription(defaultDescription);
    }
  }, [customName, defaultTitles, defaultDescription, updateTitlesWithCustomName]);

  const fetchKeywords = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `https://keyword-research-for-youtube.p.rapidapi.com/yttags.php?keyword=${encodeURIComponent(searchTerm)}`,
        {
          method: 'GET',
          headers: {
            'x-rapidapi-host': 'keyword-research-for-youtube.p.rapidapi.com',
            'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY,
          },
        }
      );

      const data = await response.json();
      if (data && data.related_keywords) {
        const sortedKeywords = data.related_keywords.sort((a, b) => {
          return (
            b.monthlysearch - a.monthlysearch ||
            b.overallscore - a.overallscore ||
            a.competition_score - b.competition_score
          );
        });

        let filteredKeywords = [];
        let charCount = 0;

        const greenKeywords = sortedKeywords.filter(
          (keyword) => keyword.overallscore > 60 && keyword.difficulty.toLowerCase() === 'low'
        );

        const yellowKeywords = sortedKeywords.filter(
          (keyword) => keyword.overallscore > 55 && keyword.difficulty.toLowerCase() === 'medium'
        );

        const otherKeywords = sortedKeywords.filter(
          (keyword) => !greenKeywords.includes(keyword) && !yellowKeywords.includes(keyword)
        );

        const combinedKeywords = [...greenKeywords, ...yellowKeywords, ...otherKeywords];

        for (let keyword of combinedKeywords) {
          if (charCount + keyword.keyword.length <= 400) {
            filteredKeywords.push(keyword);
            charCount += keyword.keyword.length + 2;
          } else {
            break;
          }
        }

        setKeywords(filteredKeywords);
        setTotalChars(charCount);
      }
    } catch (error) {
      console.error('Failed to fetch keywords:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAndGenerateThumbnail = async (text) => {
    try {
      const response = await fetch('/thumbnails.json'); // Fetch from public directory
      const thumbnails = await response.json();
      const randomThumbnailUrl = thumbnails[Math.floor(Math.random() * thumbnails.length)];
      const base64Image = await convertToBase64(randomThumbnailUrl);
      setBaseImage(base64Image);
      const generatedThumbnail = await generateThumbnail(base64Image, text);
      setThumbnailUrl(generatedThumbnail);
    } catch (error) {
      console.error('Failed to fetch and generate thumbnail:', error);
    }
  };

  const convertToBase64 = (url) => {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  };

  const generateThumbnail = (imageUrl, text) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageUrl;
  
    return new Promise((resolve) => {
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  
        // Apply a grainy film filter effect
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const noise = (Math.random() - 0.5) * 50;
          data[i] += noise;     // Red
          data[i + 1] += noise; // Green
          data[i + 2] += noise; // Blue
        }
        ctx.putImageData(imageData, 0, 0);
  
        ctx.font = 'bold 100px "Oswald"'; // Use Oswald font from Google Fonts
        ctx.fillStyle = 'white'; // White text color
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
  
        // Adjust the x coordinate to move text 2% to the right
        const x = 50 + (canvas.width * 0.1);
        const y = canvas.height / 2;
  
        // Add text glow effect
        ctx.shadowColor = 'rgba(255, 255, 255, 0.7)';
        ctx.shadowBlur = 20; // Increase this value for more blur
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
  
        // Draw text multiple times to enhance the glow effect
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.fillText(text, x, y);
        ctx.fillText(text, x, y);
        ctx.fillText(text, x, y);
  
        // Draw the main text
        ctx.shadowBlur = 0; // Reset shadow blur
        ctx.fillStyle = 'white';
        ctx.fillText(text, x, y);
  
        resolve(canvas.toDataURL());
      };
    });
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = thumbnailUrl;
    link.download = 'thumbnail.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getTagClassName = (keyword) => {
    if (keyword.overallscore > 60 && keyword.difficulty.toLowerCase() === 'low') {
      return 'tag tag-green';
    } else if (keyword.overallscore > 55 && keyword.difficulty.toLowerCase() === 'medium') {
      return 'tag tag-yellow';
    } else {
      return 'tag';
    }
  };

  const copyTags = () => {
    const tags = keywords.map((keyword) => keyword.keyword).join(', ');
    navigator.clipboard.writeText(tags).then(() => {
      setNotificationMessage('Your Tags have been copied 🫡');
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }).catch((err) => {
      console.error('Failed to copy tags:', err);
    });
  };

  const copyTitle = (title) => {
    navigator.clipboard.writeText(title).then(() => {
      setNotificationMessage('This title has been copied 🫡');
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }).catch((err) => {
      console.error('Failed to copy title:', err);
    });
  };

  const copyDescription = () => {
    navigator.clipboard.writeText(description).then(() => {
      setNotificationMessage('This description has been copied 🫡');
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }).catch((err) => {
      console.error('Failed to copy description:', err);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchKeywords();
    }
  };

  return (
    <div className="keyword-finder">
      <div className="header">
        Typebeat Keyword <span>Ranker</span>
      </div>
      <input
        type="text"
        className="input-field"
        placeholder="Enter keyword"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="generate-btn" onClick={fetchKeywords}>
        Find Keywords
      </div>
      {loading && <div className="loading-text">Loading...</div>}
      {keywords.length > 0 && (
        <div className="tags-container">
          {keywords.map((keyword, index) => (
            <div key={index} className={getTagClassName(keyword)}>
              {keyword.keyword}
            </div>
          ))}
        </div>
      )}
      {keywords.length > 0 && (
        <div className="footer">
          <button className="copy-btn" onClick={copyTags}>
            Copy Tags
          </button>
          <div className="char-counter">
            {totalChars}/400 characters
          </div>
          <div className="explanation">
            <div className="green-text">Green: Easier to rank</div>
            <div className="yellow-text">Yellow: Medium difficulty to rank</div>
            <div>No color: A bit harder to rank</div>
          </div>
        </div>
      )}
      {titles.length > 0 && (
        <>
          <h3 className="section-heading">Suggested Titles</h3>
          <div className="title-suggestions-container">
            {titles.map((title, index) => (
              <div key={index} className="title" onClick={() => copyTitle(title)}>
                {title}
              </div>
            ))}
          </div>
        </>
      )}
      {description && (
        <>
          <h3 className="section-heading">Suggested Description</h3>
          <div className="description-container">
            <div className="description">
              {description.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
            <button className="copy-description-btn" onClick={copyDescription}>
              Copy Description
            </button>
          </div>
        </>
      )}
      {thumbnailUrl && (
        <>
          <h3 className="section-heading">Suggested Thumbnail</h3>
          <div className="thumbnail-container">
            <img src={thumbnailUrl} alt="Suggested Thumbnail" className="thumbnail-image" />
            <div className="custom-name-container">
              <input
                type="text"
                className="custom-name-input"
                placeholder="Fill your custom name for your beat."
                value={customName}
                onChange={(e) => setCustomName(e.target.value)}
              />

            </div>
          </div>
        </>
      )}
      <canvas ref={canvasRef} width="1280" height="720" style={{ display: 'none' }}></canvas>
      {showNotification && (
        <div className="notification show">
          {notificationMessage}
        </div>
      )}
    </div>
  );
};

export default KeywordFinder;
